export const analyzeStrategy = params => {
  // console.log('analyzeStrategy: ', params)
  const hasValue = []
  if (params?.generalInfo?.website) hasValue.push('Website')
  if (params?.addtlInfo?.street) hasValue.push('Street')
  if (params?.addtlInfo?.city) hasValue.push('City')
  if (params?.addtlInfo?.state) hasValue.push('State')
  if (params?.addtlInfo?.zip) hasValue.push('Zip')
  if (params?.addtlInfo?.cmsUsed) hasValue.push('CMS Used')
  if (params?.addtlInfo?.productsAndServices) hasValue.push('Products and Services')
  // if (params && params.social && Array.isArray(params.social) && params.social.length > 0) hasValue.push('Social')
  if (params?.addtlInfo?.marketingObjective) hasValue.push('Marketing Objective')
  if (params?.addtlInfo?.geoTargeting) hasValue.push('Geographic Targeting')
  const decimalProgress = hasValue.length / 9
  const percentage = parseFloat((decimalProgress * 100).toFixed(0)) // Convert to integer percentage and ensure it's a number
  const fieldsCompleted = hasValue.length
  // console.log('FIELDS COMPLETED: ', hasValue)
  return { progress: percentage, completed: fieldsCompleted }
}
