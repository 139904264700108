import React, { useContext, useEffect, useState } from 'react'
import { Box, Typography, Grid, Button, useTheme, CircularProgress, Tooltip } from '@mui/material'
import { InfoBox, InfoRow, InfoLabel, InfoValueWrapper, CustomInfoBox } from './style'
import { useUsersById } from '../../api/customHooks/useUsersById'
import AssignOwnerAutocomplete from '../EditModeComponents/AssignOwnerAutocomplete'
import AssignCompaniesAutocomplete from '../EditModeComponents/AssignCompaniesAutocomplete'
import { CreateButton, SaveButton } from '../ContractDialog/style'
import WarningIcon from '@mui/icons-material/Warning'
import PandaDocImageComponent from '../AvatarImageComponents/PandaDocImageComponent'
import { useDeals } from '../../api/aws/useDeals'
import { useParams } from 'react-router-dom'
import { useOrganizationById } from '../../api/aws/useOrganization'
import DetailsLoading from '../Loading/DetailsLoading'
import DealStatusSelect from '../DealStatusSelect/DealStatusSelect'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import ApprovalRequestButton from '../ui/approval-request-button'
import { useActiveUser } from '../../api/slack/useActiveUser'

const EditDealInfo = ({
  // newDeal,
  // setNewDeal,
  organization,
  contractedBy,
  onUpdate,
  isCreating,
  onClose,
  onSave,
  isValid,
  onCreate,
  validationErrors,
}) => {
  const { newDeal, setNewDeal, resetState } = useDealDetailsContext()
  // const dealId = newDeal ? newDeal.id : null
  const theme = useTheme()
  const gridBgColor = theme.palette.mode === 'dark' ? '#1e2735' : 'rgba(255,255,255)'
  const { approvalState, handleRequestApproval } = useDealDetailsContext()
  const [isError, setIsError] = useState(false)
  const verticalsApprovalNotRequired = ['Student', 'Multifamily']
  const [isRequesting, setIsRequesting] = useState(false)
  const msaApprovers = ['U015KLM51B6', 'U06LJAMPWJ1', 'UHKAA49CJ', 'U062JDP8R5X', 'U01FK2W9YNS']
  const { activeUser } = useActiveUser()
  const [canCreateContract, setCanCreateContract] = useState(
    organization && Array.isArray(organization.vertical)
      ? !verticalsApprovalNotRequired.includes(organization.vertical)
      : false
  )
  const handleCancel = () => {
    resetState()
    onClose()
  }
  const handleApprovalRequest = () => {
    console.log('Approval Requested!!!!!!')
    handleRequestApproval(newDeal, activeUser, msaApprovers)
  }
  return (
    <>
      <CustomInfoBox sx={{ backgroundColor: gridBgColor, position: 'relative' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography
            sx={{
              color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)',
            }}
            variant='h6'
            gutterBottom
          >
            Information
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {isCreating && (
              <CircularProgress
                sx={{
                  color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'black',
                }}
              />
            )}
            {isError && <Typography color='error'>Error creating deal</Typography>}
            <Button onClick={handleCancel} color='primary' variant='outlined'>
              Cancel
            </Button>
            <SaveButton onClick={onSave} color='secondary' variant='contained'>
              Save
            </SaveButton>
            {newDeal && !newDeal.noSignature && (
              <Tooltip
                title={
                  // Only show the tooltip if the deal needs approval and `canCreateContract` is false
                  newDeal.needsApproval && !newDeal.isApproved && !newDeal.isCompleted
                    ? 'Deal needs approval before creating contract'
                    : ''
                }
              >
                <Box>
                  {newDeal &&
                  newDeal.needsApproval &&
                  !newDeal.isApproved &&
                  !newDeal.isCompleted &&
                  !['IO'].includes(newDeal.type) ? (
                    <ApprovalRequestButton
                      isRequested={newDeal.isRequested}
                      isRequesting={isRequesting}
                      onRequest={handleApprovalRequest}
                      startIcon={true}
                    />
                  ) : (
                    <CreateButton
                      onClick={onCreate}
                      color='primary'
                      variant='contained'
                      startIcon={<PandaDocImageComponent size={30} />}
                    >
                      Create Contract
                    </CreateButton>
                  )}
                </Box>
              </Tooltip>
            )}
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={newDeal && (newDeal.noSignature || newDeal.isCompleted) ? 3 : 4}>
            <InfoRow>
              <InfoLabel>Owner</InfoLabel>
              <InfoValueWrapper>
                <AssignOwnerAutocomplete
                  userId={(newDeal.owner && newDeal.owner[0]) || ''}
                  label=''
                  // onChange={handleCompanyChange}
                  context={'owner'}
                  // onBlur={handleObjectBlur}
                  multiple={false}
                />
              </InfoValueWrapper>
            </InfoRow>
          </Grid>
          <Grid item xs={12} sm={newDeal && (newDeal.noSignature || newDeal.isCompleted) ? 3 : 4}>
            <InfoRow>
              <InfoLabel>Organization</InfoLabel>
              <InfoValueWrapper>
                <AssignCompaniesAutocomplete
                  label=''
                  value={organization ? [organization] : []}
                  context={'organizations'}
                  disabled={true}
                />
              </InfoValueWrapper>
            </InfoRow>
          </Grid>
          <Grid item xs={12} sm={newDeal && (newDeal.noSignature || newDeal.isCompleted) ? 3 : 4}>
            <InfoRow>
              <InfoLabel>Contracted By</InfoLabel>
              <InfoValueWrapper>
                <AssignCompaniesAutocomplete
                  label=''
                  value={contractedBy ? [contractedBy] : []}
                  context={'organizations'}
                  disabled={true}
                />
              </InfoValueWrapper>
            </InfoRow>
          </Grid>
          {newDeal && (newDeal.noSignature || newDeal.isCompleted) && (
            <Grid item xs={12} sm={newDeal && (newDeal.noSignature || newDeal.isCompleted) ? 3 : 4}>
              <InfoRow>
                <InfoLabel sx={{ pt: 0.8 }}>Deal Status</InfoLabel>
                <InfoValueWrapper>
                  <DealStatusSelect
                    newDeal={newDeal}
                    setNewDeal={setNewDeal}
                    error={validationErrors.dealStatus}
                    helperText={validationErrors.dealStatus && 'Deal Status is required'}
                  />
                </InfoValueWrapper>
              </InfoRow>
            </Grid>
          )}
        </Grid>
      </CustomInfoBox>
    </>
  )
}

export default EditDealInfo
