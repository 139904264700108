import React from 'react'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import { IconButton, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'

const StrategyIcon = ({ color, disabled, url, size }) => (
  <IconButton sx={{ display: 'flex', alignItems: 'center', height: 45, width: 45 }}>
    <Link to={url}>
      <TrackChangesIcon
        style={{ height: size ? size : 30, width: size ? size : 30, color: color ? color : 'rgb(116, 113, 113)' }}
      />
    </Link>
  </IconButton>
)

export default StrategyIcon

{
  /*
</IconButton>
<Tooltip title='Strategy'>
    <IconButton disabled={disabled}>
      <Link to={url}>
        <TrackChangesIcon style={{ fontSize: 30, color: color ? color : 'rgba(251, 192, 147, 1)' }} />
      </Link>
    </IconButton>
  </Tooltip>
  */
}
