import React, { useEffect } from 'react'
import { Grid, Box, Typography, useTheme } from '@mui/material'
import AiRenderProductSpecificFields from './AiRenderProductSpecificFields'
import AiRenderSelectedProduct from './AiRenderSelectedProducts'
import RenderStrategySection from './RenderStrategySection'
import { editFieldConfig } from './editFieldConfig'

const AiStrategyContent = ({ sectionKey, sectionLabel, section, isEditMode, onServiceFieldChange }) => {
  const theme = useTheme()
  // Ensure the section is at least an empty array to avoid undefined errors
  const services = section || []

  // Check for empty services array before rendering
  if (!services.length) {
    return null
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography
          sx={{ color: theme.palette.mode === 'dark' ? 'rgba(255,255,255,1)' : 'rgba(0,0,0,1)', ml: 1 }}
          variant='h6'
          gutterBottom
        >
          {sectionLabel}
        </Typography>
      </Grid>
      <Box
        sx={{ bgcolor: theme.palette.background.main, border: '1px solid', borderColor: theme.palette.text.secondary }}
      >
        {services.map(service => (
          <Box key={service.instanceId}>
            <Grid item xs={12} pb={0.5}>
              <AiRenderSelectedProduct service={service} />
            </Grid>
            <AiRenderProductSpecificFields
              sectionKey={sectionKey}
              key={service.instanceId}
              service={service}
              isEditMode={isEditMode}
              onChange={onServiceFieldChange}
            />
          </Box>
        ))}
      </Box>
    </>
  )
}

export default AiStrategyContent
