import React, { useState, useEffect } from 'react'
import {
  Typography,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  FormHelperText,
  Box,
} from '@mui/material'
import { typeSelectStyles } from '../ContractDialog/style'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'

export const dealTypeOptions = [
  {
    label: 'Marketing',
    color: 'rgb(88, 188, 128)',
    subOptions: [
      'Marketing Services Agreement',
      'Revised Marketing Services Agreement',
      'Insertion Order',
      'Marketing Services Agreement - Housing',
      'Revised Marketing Services Agreement - Housing',
    ],
  },
  {
    label: 'Creative',
    color: 'rgb(165, 147, 255)',
    subOptions: ['Creative Services Agreement', 'Revised Creative Services Agreement'],
  },
]

export const renderDealType = type => {
  switch (type) {
    case 'Marketing Services Agreement':
      return 'MSA'
    case 'Revised Marketing Services Agreement':
      return 'RMSA'
    case 'Insertion Order':
      return 'IO'
    case 'Marketing Services Agreement - Housing':
      return 'MSA - Housing'
    case 'Revised Marketing Services Agreement - Housing':
      return 'RMSA - Housing'
    case 'Creative Services Agreement':
      return 'CSA'
    case 'Revised Creative Services Agreement':
      return 'RCSA'
    default:
      return type
  }
}

const dealStatusToDisable = ['Sent', 'Viewed']

export const flattenedOptions = dealTypeOptions.flatMap(option =>
  option.subOptions.map(subOption => ({
    name: subOption,
    color: option.color,
  }))
)

const ReviseDealType = ({ error, helperText, onChange }) => {
  // console.log('[ReviseDealType] newDeal: ', newDeal)
  const { newDeal, setNewDeal } = useDealDetailsContext()
  const theme = useTheme()
  const [selectedValue, setSelectedValue] = useState('')

  useEffect(() => {
    if (newDeal.type) {
      setSelectedValue(newDeal.type)
    }
  }, [newDeal.type])

  const handleChange = event => {
    const newValue = event.target.value
    setSelectedValue(newValue)
    onChange(renderDealType(newValue))
    setNewDeal(prev => ({
      ...prev,
      type: newValue,
    }))
  }

  return (
    // <Box sx={{ width: '100%' }}>
    <FormControl fullWidth variant='outlined' sx={{ width: '100%', height: '65px' }}>
      <InputLabel
        id={`revise-type-label`}
        sx={{
          color: theme.palette.mode === 'dark' ? 'rgb(255,255,255,0.5)' : 'rgb(0,0,0,0.5)',
          fontSize: '16px',
          transform: 'translate(9px, 11px) scale(1)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -1px) scale(0.75)',
          },
          '&.Mui-focused': {
            color: theme.palette.mode === 'dark' ? 'rgb(121 212 255)' : 'black',
          },
        }}
      >
        {'Type'}
      </InputLabel>
      <Select
        labelId='revise-select-type-label'
        id='type-select'
        value={selectedValue || ''}
        onChange={handleChange}
        label={'Type'}
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
        disabled={
          (newDeal && newDeal.isCompleted) ||
          dealStatusToDisable.includes(newDeal && newDeal.status) ||
          (newDeal && newDeal.stage === 'Deal Won')
        }
      >
        {flattenedOptions.map(option => (
          <MenuItem key={option.name} value={renderDealType(option.name)}>
            <Chip
              size='small'
              style={{
                backgroundColor: option.color,
                width: 14,
                height: 14,
                borderRadius: '50%',
                marginRight: 8,
              }}
            />
            <Typography variant='subtitle1'>{renderDealType(option.name)}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>

    // </Box>
  )
}

export default ReviseDealType
