import React, { useState } from 'react'
import { Box, Grid, InputLabel, Typography, useTheme } from '@mui/material'
import { formatCurrency } from '../DealsKanban/aggregateStageTotals'
import RenderServiceIcon from '../RenderServiceIcons/RenderServiceIcon'

const AiRenderSelectedProduct = ({ service }) => {
  const theme = useTheme()
  const [isHovered, setIsHovered] = useState(false)

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        p: 1,
        border: '1px solid',
        borderColor: theme.palette.text.secondary,
        bgcolor: theme.palette.background.content,
        zIndex: 17, // Adjust stacking order
        width: '100%',
      }}
    >
      <Grid
        item
        xs={4}
        sx={{ display: 'flex', flexDirection: 'column' }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        gap={0.5}
      >
        <Box sx={{ position: 'relative' }}>
          <InputLabel
            sx={{
              fontSize: 12,
              opacity: isHovered ? 1 : 0,
              transition: 'opacity 0.5s ease-in-out',
            }}
          >
            {isHovered ? `${service.instanceId}` : ''}
          </InputLabel>
          <InputLabel
            sx={{
              fontSize: 12,
              opacity: !isHovered ? 1 : 0,
              transition: 'opacity 0.5s ease-in-out',
            }}
          >
            {!isHovered ? 'Service' : ''}
          </InputLabel>
        </Box>
        <RenderServiceIcon service={service.productName} />
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <InputLabel sx={{ fontSize: 12 }}>Payer</InputLabel>
        <Typography>{service.payer || '—'}</Typography>
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <InputLabel sx={{ fontSize: 12 }}>Account Used</InputLabel>
        <Typography>{service.accountUsed || '—'}</Typography>
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <InputLabel sx={{ fontSize: 12 }}>Media Spend</InputLabel>
        <Typography>{formatCurrency(service.mediaSpend)}</Typography>
      </Grid>
      <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <InputLabel sx={{ fontSize: 12 }}>Price</InputLabel>
        <Typography>{formatCurrency(service.price)}</Typography>
      </Grid>
    </Grid>
  )
}

export default AiRenderSelectedProduct

// import React, { useState } from 'react'
// import { Box, Grid, InputLabel, Typography, useTheme } from '@mui/material'
// import { formatCurrency } from '../DealsKanban/aggregateStageTotals'
// import RenderServiceIcon from '../RenderServiceIcons/RenderServiceIcon'

// const AiRenderSelectedProduct = ({ service }) => {
//   const theme = useTheme()
//   const [isHovered, setIsHovered] = useState(false)
//   const renderGridItem = (label, value, formatter = v => v) => (
//     <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
//       <InputLabel sx={{ fontSize: 12 }}>{label}</InputLabel>
//       <Typography>{formatter(value) || '—'}</Typography>
//     </Grid>
//   )

//   return (
//     <Grid
//       container
//       sx={{
//         display: 'flex',
//         alignItems: 'center',
//         p: 1,
//         border: '1px solid',
//         borderColor: theme.palette.text.secondary,
//         bgcolor: theme.palette.background.content,
//         zIndex: 17,
//         width: '100%',
//       }}
//     >
//       <Grid
//         item
//         xs={4}
//         sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}
//         onMouseEnter={() => setIsHovered(true)}
//         onMouseLeave={() => setIsHovered(false)}
//       >
//         <Box sx={{ position: 'relative' }}>
//           <InputLabel
//             sx={{
//               fontSize: 12,
//               opacity: isHovered ? 1 : 0,
//               transition: 'opacity 0.5s ease-in-out',
//             }}
//           >
//             {isHovered ? service.instanceId : 'Service'}
//           </InputLabel>
//         </Box>
//         <RenderServiceIcon service={service.productName} />
//       </Grid>
//       {renderGridItem('Payer', service.payer)}
//       {renderGridItem('Account Used', service.accountUsed)}
//       {renderGridItem('Media Spend', service.mediaSpend, formatCurrency)}
//       {renderGridItem('Price', service.price, formatCurrency)}
//     </Grid>
//   )
// }

// export default AiRenderSelectedProduct
