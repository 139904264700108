import { v4 as uuidv4 } from 'uuid'
import { displayProducts, productFieldsConfig, searchProducts, socialProducts, seoProducts } from './editFieldConfig'

export const addServiceToStrategy = (strategyState, product) => {
  const service = {
    ...product,
    instanceId: product.instanceId || uuidv4(), // Ensure each product has a unique instanceId
  }

  // Add the product to the respective strategy category based on productName
  if (searchProducts.includes(product.productName)) {
    strategyState.paidSearch = addUniqueService(strategyState?.paidSearch, service)
  } else if (socialProducts.includes(product.productName)) {
    strategyState.paidSocial = addUniqueService(strategyState?.paidSocial, service)
  } else if (displayProducts.includes(product.productName)) {
    strategyState.display = addUniqueService(strategyState?.display, service)
  } else if (seoProducts.includes(product.productName)) {
    strategyState.seo = addUniqueService(strategyState?.seo, service)
  } else if (product.productName.includes('Website')) {
    strategyState.website = addUniqueService(strategyState?.website, service)
  }

  return strategyState
}

// Helper function to add services based on instanceId uniqueness
const addUniqueService = (existingStrategy = [], service) => {
  if (!Array.isArray(existingStrategy)) {
    console.error('Expected an array for existingStrategy, but got:', existingStrategy)
    existingStrategy = [] // Fallback to an empty array if it's not an array
  }

  const index = existingStrategy.findIndex(item => item.instanceId === service.instanceId)
  if (index !== -1) {
    existingStrategy[index] = service // Update existing service
  } else {
    existingStrategy.push(service) // Add new service
  }

  return existingStrategy
}

export const determineStrategyState = (products = [], organization = {}, existingStrategyState = {}) => {
  const initialState = {
    paidSearch: existingStrategyState.paidSearch || [],
    paidSocial: existingStrategyState.paidSocial || [],
    seo: existingStrategyState.seo || [],
    smm: existingStrategyState.smm || [],
    website: existingStrategyState.website || [],
    display: existingStrategyState.display || [],
    addtlInfo: {
      active: true,
      marketingObjective:
        organization?.marketingObjective || existingStrategyState?.addtlInfo?.marketingObjective || '',
      geoTargeting: organization?.geoTargeting || existingStrategyState?.addtlInfo?.geoTargeting || '',
      cmsUsed: organization?.cmsUsed || existingStrategyState?.addtlInfo?.cmsUsed || '',
      demographicTargeting:
        organization?.demographicTargeting || existingStrategyState?.addtlInfo?.demographicTargeting || '',
      productsAndServices:
        organization?.productsAndServices || existingStrategyState?.addtlInfo?.productsAndServices || '',
      callTracking: existingStrategyState?.addtlInfo?.callTracking || '',
    },
    integration: {
      active: true,
      targetingSpecifics:
        organization?.targetingSpecifics || existingStrategyState?.integration?.targetingSpecifics || '',
      formPresent: existingStrategyState?.integration?.formPresent || '',
    },
    generalInfo: {
      active: true,
      organizationName: organization?.name || existingStrategyState?.generalInfo?.organizationName || '',
      website: organization?.website || existingStrategyState?.generalInfo?.website || '',
      type: existingStrategyState?.generalInfo?.type || '',
      vertical: organization?.vertical?.[0] || existingStrategyState?.generalInfo?.vertical || '',
      groContact: existingStrategyState?.generalInfo?.groContact || '',
      orgContact: existingStrategyState?.generalInfo?.orgContact || '',
    },
  }

  if (!Array.isArray(products)) {
    console.error('Expected products to be an array, but got:', products)
    return {
      strategyState: initialState,
      updatedProducts: [],
    }
  }

  const updatedProducts = products.map(product => ({
    ...product,
    instanceId: product.instanceId || uuidv4(), // Ensure each product has an instanceId
  }))

  const strategyState = updatedProducts.reduce(
    (acc, product) => {
      const category = acc[product.productName] || []
      return addServiceToStrategy(acc, product, category)
    },
    { ...initialState }
  )

  return {
    ...strategyState,
    updatedProducts,
  }
}
