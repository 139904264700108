import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, useTheme, useMediaQuery, Box } from '@mui/material'
import GenerateContractTable from './GenerateContractTable'
import {
  contractMap,
  generalInfoMap,
  payInfoMap,
  totalsMap,
  ioTotalsMap,
  ioContractMap,
  csaTotalsMap,
  billingInfoMap,
} from './dataMaps'
import { useOrganizations } from '../../api/aws/useOrganizations'
import DetailsLoading from '../Loading/DetailsLoading'
import GenerateTable from './GenerateTable'
import { useOrganizationById } from '../../api/aws/useOrganization'
import { calculateTotalMonthsForProducts } from '../../utility/calculateTotalMonths'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import useTotals from '../../api/customHooks/useTotals'
import { useUsersById } from '../../api/customHooks/useUsersById'
import { useContactsById } from '../../api/customHooks/useContactsById'
import { useGetQuery } from '../../api/aws/useGetQuery'
import { useContactById } from '../../api/customHooks/useContactById'
import { useUser } from '../../api/aws/useUser'

const ReviewDeal = () => {
  const { dealId } = useParams()
  const { newDeal, productRows } = useDealDetailsContext()
  const [productList, setProductList] = useState(newDeal?.products || [])
  const [selectedOrganization, setSelectedOrganization] = useState()
  const totals = useTotals(productRows, newDeal?.implementationFee || 0, newDeal?.type)
  const orgId = newDeal && Array.isArray(newDeal.organizations) ? newDeal.organizations[0] : []
  const { organization, isOrganizationLoading, isOrganizationError } = useOrganizationById(orgId)
  const contractedById = organization && Array.isArray(organization.contractedBy) ? organization.contractedBy[0] : []
  const billingContactId =
    organization && Array.isArray(organization.billingContact) ? organization.billingContact[0] : []
  const {
    organization: contractedBy,
    isOrganizationLoading: isContractedByLoading,
    isOrganizationError: isContractedByError,
  } = useOrganizationById(contractedById)
  const createdById = newDeal && newDeal.createdBy ? newDeal.createdBy : ''
  const { user: createdBy, isUsersByIdLoading, isUsersByIdError } = useUser(createdById)

  const { data: billingContact } = useContactById(billingContactId)
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  useEffect(() => {
    if (newDeal && organization) {
      console.log('DEAL: ', newDeal)
      setSelectedOrganization(organization)
    }
  }, [newDeal, organization])

  if (isOrganizationLoading || isUsersByIdLoading || isContractedByLoading) {
    return <DetailsLoading />
  }
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start', // Align items to the top to prevent content from being cut off
        height: 'calc(100vh - 122px)', // Ensure the content fits within the viewport
        overflowY: 'auto', // Enable vertical scrolling if needed
        padding: 2, // Add some padding to prevent content from touching edges
      }}
    >
      <Grid container spacing={2} p={2}>
        {/* First Row with General Info and Totals Info */}
        <Grid item xs={12} sm={12}>
          <GenerateContractTable options={generalInfoMap(organization)} section={'General Info'} />
        </Grid>
        <Grid item xs={12} sm={12}>
          <GenerateContractTable
            options={
              newDeal && newDeal.type === 'IO'
                ? ioTotalsMap(newDeal, totals)
                : ['CSA', 'RCSA'].includes(newDeal.type)
                ? csaTotalsMap(newDeal, totals)
                : totalsMap(totals)
            }
            section={'Totals Info'}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <GenerateContractTable
            options={billingInfoMap(newDeal, contractedBy, createdBy, billingContact)}
            section={'Billing Info'}
          />
        </Grid>
        <Grid item xs={12}>
          <GenerateTable
            options={newDeal && newDeal.type === 'IO' ? ioContractMap(productList) : contractMap(productList)}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ReviewDeal
// overall start and end date
// contracted by
// billing contact
// when selecting billing contact autofill numbers in organizations
// who created the contract
// filtering for solamar not working
