import React, { useEffect, useState } from 'react'
import Sprint from './Sprint.tsx'
import { Box, Stack, TextField, Button, Grid } from '@mui/material'
import { DateRangePicker, DateRangePickerProps } from '@mui/x-date-pickers-pro/DateRangePicker'
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { differenceInDays } from 'date-fns'
import { useCreateSprint } from '../../api/aws/useCreateSprint'
import { useUpdateItem } from '../../api/aws/useUpdateItem'
import { sprintListStyle, stackStyle } from './style.js'
import dayjs from 'dayjs'
import { useSprints } from '../../api/aws/useSprints.js'

interface SprintData {
  name: string
  priority: string
  estimate: string
  type: string
  startDate: string
  endDate: string
  description: string
  isCompleted: boolean
}

const Sprints: React.FC = () => {
  const { sprints, isSprintsLoading, isSprintsError } = useSprints()
  const [currentSprint, setCurrentSprint] = useState<SprintData[]>([])
  const [newSprint, setNewSprint] = useState<SprintData>({
    name: '',
    priority: '',
    type: '',
    estimate: '',
    startDate: '',
    endDate: '',
    description: '',
    isCompleted: false,
  })
  const [dateRange, setDateRange] = useState<DateRangePickerProps<Date>['value']>([null, null])
  const { mutate: createSprint } = useCreateSprint()
  const { mutate: updateItem } = useUpdateItem()

  const formatDateString = (date: Date | null): string => {
    if (!date) return '' // Handle null values
    return dayjs(date).format('MM-DD-YYYY') // Format Date objects
  }

  const calculateEstimate = (start: Date | null, end: Date | null) => {
    if (start && end) {
      return `${differenceInDays(end, start) + 1} days`
    }
    return ''
  }

  const addSprint = () => {
    if (dateRange && dateRange[0] && dateRange[1]) {
      const estimate = calculateEstimate(dateRange[0], dateRange[1])
      const updatedSprint: SprintData = {
        ...newSprint,
        estimate,
        startDate: formatDateString(dateRange[0]), // Now passing Date
        endDate: formatDateString(dateRange[1]), // Now passing Date
      }
      setCurrentSprint([...sprints, updatedSprint])
      setNewSprint({
        name: '',
        priority: '',
        type: '',
        estimate: '',
        startDate: '',
        endDate: '',
        description: '',
        isCompleted: false,
      })
      setDateRange([null, null])
      handleCreateSprint(updatedSprint)
    }
  }

  const handleCreateSprint = (sprint: SprintData) => {
    console.log('newSprint for creation: ', sprint)
    createSprint(sprint, {
      onSuccess: data => {
        console.log('Success! ', data)
      },
      onError: error => {
        console.log('Error creating sprint: ', error)
      },
    })
  }

  const handleCompleteSprint = (index: number) => {
    const sprint = sprints[index]
    const updatedSprint = { ...sprint, isCompleted: !sprint.isCompleted }

    updateItem(
      {
        primaryKey: 'sprint_uuid', // Update with the correct primary key field name
        tableName: 'sprints', // Update with the correct table name
        itemId: sprint.id, // Update with the correct item ID
        fieldName: 'isCompleted',
        newValue: updatedSprint.isCompleted,
      },
      {
        onSuccess: () => {
          console.log('Sprint updated successfully')
          setCurrentSprint(prevSprints => {
            const newSprints = [...prevSprints]
            newSprints[index] = updatedSprint
            return newSprints
          })
        },
        onError: error => {
          console.log('Error updating sprint: ', error)
        },
      }
    )
  }

  useEffect(() => {
    if (dateRange && Array.isArray(dateRange) && dateRange[0] && dateRange[1]) {
      const estimate = calculateEstimate(dateRange[0], dateRange[1])
      setNewSprint(prev => ({
        ...prev,
        estimate,
        startDate: formatDateString(dateRange[0]),
        endDate: formatDateString(dateRange[1]),
      }))
    }
  }, [dateRange])

  useEffect(() => {
    if (sprints) {
      console.log('Sprints: ', sprints)
    }
  }, [sprints])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack className='sprints'>
        <h2 style={{ textAlign: 'center' }}>Development Timeline</h2>
        <Grid
          container
          className='add-sprint'
          // sx={{ display: 'flex', flexDirection: 'row', gap: 1, justifyContent: 'center', alignItems: 'center' }}
        >
          <Grid
            item
            xs={4}
            sx={{ display: 'flex', flexDirection: 'row', gap: 1, justifyContent: 'center', alignItems: 'center' }}
          >
            <TextField
              type='text'
              placeholder='Name'
              value={newSprint.name}
              onChange={e => setNewSprint({ ...newSprint, name: e.target.value })}
            />
            <TextField
              type='text'
              placeholder='Priority'
              value={newSprint.priority}
              onChange={e => setNewSprint({ ...newSprint, priority: e.target.value })}
            />
          </Grid>
          <Grid
            item
            xs={3}
            sx={{ display: 'flex', flexDirection: 'row', gap: 1, justifyContent: 'center', alignItems: 'center' }}
          >
            <TextField
              type='text'
              placeholder='Type'
              value={newSprint.type}
              onChange={e => setNewSprint({ ...newSprint, type: e.target.value })}
            />
            <DateRangePicker
              value={dateRange}
              onChange={newValue => setDateRange(newValue)}
              slotProps={{
                textField: {
                  label: 'Date Range',
                  fullWidth: true,
                },
              }}
            />
          </Grid>

          <Grid
            item
            xs={3}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <TextField
              type='text'
              placeholder='Estimated Time'
              value={newSprint.estimate}
              onChange={e => setNewSprint({ ...newSprint, estimate: e.target.value })}
              disabled
            />
            <TextField
              multiline
              placeholder='Description'
              value={newSprint.description}
              onChange={e => setNewSprint({ ...newSprint, description: e.target.value })}
            />
          </Grid>
          <Grid item xs={2}>
            <Button variant='contained' onClick={addSprint}>
              Add Sprint
            </Button>
          </Grid>
        </Grid>
        {/* <Grid container>
          {Array.isArray(sprints) && sprints.length > 0
            ? sprints.map((sprint, index) => (
                <Sprint
                  key={index}
                  name={sprint.name}
                  type={sprint.type}
                  estimate={sprint.estimate}
                  startDate={sprint.startDate}
                  endDate={sprint.endDate}
                  description={sprint.description}
                  isCompleted={sprint.isCompleted}
                  onComplete={() => handleCompleteSprint(index)}
                />
              ))
            : null}
        </Grid> */}
      </Stack>
    </LocalizationProvider>
  )
}

export default Sprints
