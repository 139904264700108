import React, { createContext, useContext, useEffect, useState } from 'react'
import { useSnackbarContext } from '../SnackbarProvider/SnackbarProvider'
import { useUserProvider } from '../UsersProvider/UsersProvider'
import { useQueryClient } from '@tanstack/react-query'
import { createDealWonPayload } from '../../components/Notifications/createDealWonPayload'
import { useNotifyMessage } from '../../api/slack/useNotifyMessage'
import { useActiveUser } from '../../api/slack/useActiveUser'
import DetailsLoading from '../../components/Loading/DetailsLoading'
const WebsocketContext = createContext()

export const WebsocketProvider = ({ children }) => {
  const { showSnackbar } = useSnackbarContext()
  const { mutate: sendNotification } = useNotifyMessage()
  const [ws, setWs] = useState(null)
  const [customMessage, setCustomMessage] = useState('')
  const [connectedUsers, setConnectedUsers] = useState([])
  const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL || 'grobotic.app:2096/ws'
  const { activeUser, isActiveUserLoading, isActiveUserError } = useActiveUser()
  const { user } = activeUser ? activeUser : {}
  const userId = user ? user.id : ''
  const firstName = user && user.profile && user.profile.first_name ? user.profile.first_name : ''
  const lastName = user && user.profile && user.profile.last_name ? user.profile.last_name : ''
  const userName = user ? user.real_name : ''
  const queryClient = useQueryClient()

  useEffect(() => {
    if (userId && userName) {
      // console.log(`Websocket User: \nname: ${userName}, id: ${userId}`)/////////////////////
    }
  }, [userId, userName])

  useEffect(() => {
    if (isActiveUserLoading) {
      return // This is fine, simply exit early
    }
    const websocket = new WebSocket(
      `wss://${websocketUrl}?userId=${userId}&userName=${encodeURIComponent(userName)}&firstName=${encodeURIComponent(
        firstName
      )}&lastName=${encodeURIComponent(lastName)}`
    )

    websocket.onopen = () => {
      // console.log('Connected to WebSocket server')////////////////////////////////////
      if (userId) {
        websocket.userId = userId
        // console.log(`WebSocket connected for userId: ${userId}`)//////////////////////////////
      } else {
        console.warn('UserId is not available, could not attach to WebSocket')
      }
      setWs(websocket) // Store the websocket instance in state
    }

    if (isActiveUserLoading) {
      return <DetailsLoading />
    }
    websocket.onmessage = event => {
      try {
        const data = JSON.parse(event.data)

        if (data.type === 'CONNECTED_USERS') {
          // Update the list of connected users
          const totalUsers = data && Array.isArray(data.users) ? data.users.length : 0
          const users = data && Array.isArray(data.users) ? data.users : []
          // console.log('CONNECTED_USERS: ', totalUsers)///////////////////////////////
          // console.log('User Data: ', users)//////////////////////////////
          setConnectedUsers(data.users)
          if (data && Array.isArray(data.users)) {
            return data.users.map(user => {
              // console.log(`name: ${user.name} id: ${user.id}`) /////////////////////////////////////
            })
          }
        } else if (data.type === 'DEAL_WON') {
          console.log('Deal won event received:', data)

          // Trigger Slack notification for the deal won
          try {
            if (data.shouldNotify) {
              // Trigger Slack notification for the deal won
              try {
                showSnackbar(`Deal won! ${data.deal.name}`, 'broadcast')
              } catch (e) {
                console.log('Error displaying snackbar: ', e)
              }
              data.shouldNotify = false
            }
          } catch (e) {
            console.log('Error sending notifications for deal won')
          }
        } else if (data.type === 'UPDATE') {
          console.log('[Websocket Provider] UPDATE Triggered: ', data)
          queryClient
            .invalidateQueries([`/aws/${data.tableName}`, data.itemId])
            .then(result => {
              console.log('Query invalidated successfully:', `[/aws/${data.tableName}, ${data.itemId}]`)
            })
            .catch(error => {
              console.error('Error invalidating query:', error)
            })
        } else if (data.type === 'POST') {
          console.log('[Websocket Provider] POST Triggered: ', data)
          console.log('Invalidating...', `/aws/${data.tableName}`, data.itemId)
          queryClient.invalidateQueries([`/aws/${data.tableName}`, data.itemId])
        } else if (data.type === 'SAVE') {
          console.log('[Websocket Provider] SAVE Triggered: ', data)

          // Emit an event that specific components like DealPage can listen to
          const updateEvent = new CustomEvent('dealUpdate', { detail: data })
          window.dispatchEvent(updateEvent) // This will be caught in DealPage
          // console.log('[Websocket Provider] SAVE Triggered: ', data)
          // console.log('Invalidating...', `/aws/${data.tableName}`, `${data.itemId}`)
          // queryClient.invalidateQueries([`/aws/${data.tableName}`, `${data.itemId}`])
        } else if (data.type === 'DELETE') {
          console.log('[Websocket Provider] DEALS DELETE Triggered: ', data)
          // Invalidate the deleted deal
          console.log('Invalidating...', `/aws/${data.tableName}`, data.itemId)
          queryClient.invalidateQueries([`/aws/${data.tableName}`, data.itemId])
          // // Invalidate the affected organizations
          // if (data.affectedOrganizationIds && data.affectedOrganizationIds.length > 0) {
          //   console.log('Invalidating organizations....', data.affectedOrganizationIds)
          //   data.affectedOrganizationIds.forEach(orgId => {
          //     console.log('Invalidating Organization with Id...', orgId)
          //     queryClient.invalidateQueries(['organizations', orgId])
          //   })
          // }
        } else if (data.message) {
          const severity = data.severity === 'broadcast' ? 'broadcast' : 'grobotic'
          // console.log('SEVERITY: ', severity)
          // console.log('Snackbar Triggered with severity:', severity, data) // Log the determined severity
          showSnackbar(data.message, severity)
        } else if (data.customBroadcast) {
        } else {
          console.log('Other Websocket Event: ', data)
        }
      } catch (e) {
        console.error('Error parsing WebSocket message:', e)
      }
    }

    websocket.onclose = () => {
      console.log('WebSocket connection closed')
    }

    websocket.onerror = error => {
      console.error('WebSocket error:', error)
    }

    return () => {
      websocket.close()
    }
  }, [showSnackbar, websocketUrl, userId, userName, queryClient, isActiveUserLoading])

  const broadcastAnnouncement = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify({ message: customMessage, severity: 'broadcast' })) // Use `type: 'broadcast'`
    }
  }

  const websocketState = {
    customMessage,
    setCustomMessage,
    broadcastAnnouncement,
    connectedUsers,
  }

  return <WebsocketContext.Provider value={websocketState}>{children}</WebsocketContext.Provider>
}

export const useWebsocketContext = () => useContext(WebsocketContext)
