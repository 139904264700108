import React from 'react'
import { Tooltip, IconButton, Box, Typography, useTheme } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'

const RichTextTooltip = ({ content, onClick }) => {
  const theme = useTheme()
  return (
    <Tooltip
      title={
        <Box
          dangerouslySetInnerHTML={{ __html: content }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            color: theme.palette.text.primary,
            fontSize: '0.875rem',
            lineHeight: '1.4em',
          }}
        />
      }
      arrow
      interactive='true'
      placement='right'
      slotProps={{
        tooltip: {
          sx: {
            maxWidth: '600px', // Custom max-width for the tooltip container
            backgroundColor: theme.palette.background.tooltip, // Custom background color
            color: 'white',
            padding: '16px',
            borderRadius: '8px',
          },
        },
        arrow: {
          sx: {
            color: theme.palette.background.tooltip, // Make sure the arrow matches the tooltip background
          },
        },
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10], // Adjust tooltip arrow positioning
              },
            },
          ],
        },
      }}
    >
      <IconButton onClick={onClick} sx={{ padding: 1.5 }} size='small'>
        <EditIcon fontSize='small' />
      </IconButton>
    </Tooltip>
  )
}

export default RichTextTooltip

// import React from 'react'
// import { Tooltip, IconButton, Box, Typography } from '@mui/material'
// import EditIcon from '@mui/icons-material/Edit'

// const RichTextTooltip = ({ content, onClick }) => {
//   return (
//     <Tooltip
//       open={true}
//       slotProps={{
//         sx: {
//           display: 'flex',
//           flexDirection: 'column',
//           padding: '10px',
//           maxWidth: '600px',
//           width: '600px',
//           color: 'inherit',
//           fontSize: '0.875rem',
//           lineHeight: '1.4em',
//           whiteSpace: 'normal',
//           overflowWrap: 'break-word',
//           wordBreak: 'break-word',
//         },
//       }}
//       title={
//         <Box
//           dangerouslySetInnerHTML={{ __html: content }}
//           sx={{
//             display: 'flex',
//             flexDirection: 'column',
//             padding: '10px',
//             maxWidth: '600px',
//             width: '600px',
//             color: 'inherit',
//             fontSize: '0.875rem',
//             lineHeight: '1.4em',
//             whiteSpace: 'normal',
//             overflowWrap: 'break-word',
//             wordBreak: 'break-word',
//           }}
//         />
//       }
//       arrow
//       interactive
//       placement='right'
//     >
//       <IconButton onClick={onClick} sx={{ padding: 1.5 }} size='small'>
//         <EditIcon fontSize='small' />
//       </IconButton>
//     </Tooltip>
//   )
// }

// export default RichTextTooltip
