import React, { useEffect, useState } from 'react'
import { Grid, Box, Button, useTheme } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useDealDetailsContext } from '../../context/DealsDetailsProvider/DealsDetailsProvider'
import useSaveDeal from '../../api/customHooks/useSaveDeal'
import { v4 as uuidv4 } from 'uuid' // For generating unique note IDs
import AiStrategyContent from './AiStrategyContent'
import RenderStrategyHeader from './RenderStrategyHeader'
import { useOrganizationById } from '../../api/aws/useOrganization'
import StrategyNotesSection from './StrategyNotesSection'
import { useUpdateItems } from '../../api/aws/useUpdateItems'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useActiveUser } from '../../api/slack/useActiveUser'
import RenderStrategySection from './RenderStrategySection'
import { editFieldConfig } from './editFieldConfig'
import { determineStrategyState } from './determineStrategy'
import DetailsLoading from '../Loading/DetailsLoading'
import { useQueryClient } from '@tanstack/react-query'

const AiStrategy = () => {
  const { dealId } = useParams()
  const theme = useTheme()
  const { newDeal, setNewDeal, strategyState, setStrategyState } = useDealDetailsContext()
  const [generalInfo, setGeneralInfo] = useState({})
  const [addtlInfo, setAddtlInfo] = useState({})
  const [integration, setIntegration] = useState({})
  const [paidSearch, setPaidSearch] = useState({})
  const [paidSocial, setPaidSocial] = useState({})
  const [display, setDisplay] = useState({})
  const [seo, setSeo] = useState({})
  const [smm, setSmm] = useState({})
  const orgId = newDeal?.organizations?.[0] || null
  const { organization, isOrganizationLoading } = useOrganizationById(orgId)
  const { mutate: saveDeal } = useSaveDeal() // Hook for saving the newDeal
  const [isEditMode, setIsEditMode] = useState(false)
  //////// Notes ///////////
  const { activeUser } = useActiveUser()
  const { showSnackbar } = useSnackbarContext()
  const { mutate: updateItems } = useUpdateItems()
  const [newNoteContent, setNewNoteContent] = useState('')
  const [notes, setNotes] = useState([])
  const [isCreatingNote, setIsCreatingNote] = useState(false)
  const queryClient = useQueryClient()
  useEffect(() => {
    if (newDeal?.products && organization) {
      const {
        paidSearch,
        paidSocial,
        seo,
        display,
        website,
        addtlInfo,
        integration,
        generalInfo,
        updatedProducts,
      } = determineStrategyState(newDeal.products, organization, strategyState)
      console.log('[AiStrategy] setting strategyState: ', strategyState)
      setStrategyState(prev => ({
        ...prev,
        paidSearch,
        paidSocial,
        seo,
        display,
        website,
        addtlInfo,
        integration,
        generalInfo,
        updatedProducts,
      }))
    }
    // if (organization) {
    // Initialize the form states for generalInfo, addtlInfo, etc.
    const generalInfoUpdates = {
      organizationName: organization?.name || '',
      website: organization?.website || '',
      type: newDeal?.type || '',
      vertical: organization?.vertical?.[0] || '',
      groContact: newDeal?.recipients?.authorizedRecipient?.[0]?.email || '',
      orgContact: newDeal?.recipients?.contactRecipient?.[0]?.email || '',
    }
    setGeneralInfo(generalInfoUpdates)

    const addtlInfoUpdates = {
      marketingObjective: organization?.marketingObjective || '',
      geoTargeting: organization?.geoTargeting || '',
      cmsUsed: organization?.cmsUsed || '',
      demographicTargeting: newDeal?.addtlInfo?.demographicTargeting || '',
      productsAndServices: organization?.productsAndServices || '',
      callTracking: newDeal?.addtlInfo?.callTracking || '',
    }
    setAddtlInfo(addtlInfoUpdates)

    const integrationUpdates = {
      targetingSpecifics: newDeal?.integration?.targetingSpecifics || '',
      formPresent: newDeal?.integration?.formPresent || '',
    }
    setIntegration(integrationUpdates)
    // }
  }, [newDeal, organization])

  ////////////////////////////////////////////////////
  // Updates the field of a specific service in strategyState
  // const handleServiceFieldChange = (sectionKey, instanceId, fieldName, newValue) => {
  //   console.log('sectionKey', sectionKey, 'instanceId', instanceId, 'fieldName', fieldName, 'newValue', newValue)
  //   setStrategyState(prevState => ({
  //     ...prevState,
  //     [sectionKey]: prevState[sectionKey].map(service =>
  //       service.instanceId === instanceId ? { ...service, [fieldName]: newValue } : service
  //     ),
  //   }))
  // }
  const handleServiceFieldChange = (sectionKey, instanceId, fieldName, newValue) => {
    setStrategyState(prevState => {
      // Update the service in the specific section (e.g., paidSearch)
      const updatedSection = prevState[sectionKey].map(service =>
        service.instanceId === instanceId ? { ...service, [fieldName]: newValue } : service
      )

      // Recalculate updatedProducts by ensuring no duplicates
      const allProducts = [
        ...prevState.paidSearch,
        ...prevState.paidSocial,
        ...prevState.display,
        ...prevState.seo,
        ...prevState.website,
      ]

      // Create a new updatedProducts array by replacing the updated product
      const updatedProducts = allProducts.map(product =>
        product.instanceId === instanceId ? { ...product, [fieldName]: newValue } : product
      )

      return {
        ...prevState,
        [sectionKey]: updatedSection, // Update the specific section
        updatedProducts, // Ensure updatedProducts contains the latest state
      }
    })
  }

  // Function to handle saving the updated newDeal
  const handleSave = () => {
    const updatedDeal = {
      ...newDeal,
      paidSearch: strategyState.paidSearch,
      paidSocial: strategyState.paidSocial,
      display: strategyState.display,
      seo: strategyState.seo,
      generalInfo: generalInfo,
      addtlInfo: addtlInfo,
      integration: integration,
      products: strategyState.updatedProducts,
    }

    console.log('UPDATED DEAL: ', updatedDeal)
    try {
      saveDeal(
        { dealId, dealData: updatedDeal },
        {
          onSuccess: () => {
            console.log('Deal updated successfully!')

            // Invalidate queries to refetch updated data
            queryClient.invalidateQueries(['/aws/deals', updatedDeal.deal_uuid]) // Assuming 'deal' is the query key
          },
          onError: error => {
            console.error('Failed to update newDeal:', error)
          },
        }
      )
      setNewDeal({
        ...updatedDeal,
        paidSearch: strategyState.paidSearch,
        paidSocial: strategyState.paidSocial,
        display: strategyState.display,
        seo: strategyState.seo,
        generalInfo: generalInfo,
        addtlInfo: addtlInfo,
        integration: integration,
        products: strategyState.updatedProducts,
      })
    } catch (e) {
      console.error(e)
    }
  }

  const handleAddNote = () => {
    if (newNoteContent && newNoteContent !== '<p></p>') {
      setIsCreatingNote(true)
      // Avoid adding empty notes
      const newNote = {
        note_id: uuidv4(),
        contents: newNoteContent,
        owner: activeUser && activeUser.user ? [activeUser.user.id] : [],
        createdAt: new Date().toISOString(),
      }

      const updatedNotes = [...notes, newNote]
      setNotes(updatedNotes)

      updateItems(
        {
          updates: [
            {
              primaryKey: 'deal_uuid',
              tableName: 'deals',
              itemId: newDeal.deal_uuid,
              fieldName: 'notes',
              newValue: updatedNotes,
            },
          ],
        },
        {
          onSuccess: () => {
            showSnackbar('Note added successfully', 'success')
            handleClear()
          },
          onError: err => {
            console.error('Failed to add note:', err)
            showSnackbar('Failed to add note', 'error')
          },
        }
      )
    }
  }
  const handleClear = () => {
    setIsCreatingNote(false)
    setNewNoteContent('') // This clears the note content state
  }
  const handleSectionChange = setSectionState => (fieldName, value) => {
    setSectionState(prev => ({
      ...prev,
      [fieldName]: value,
    }))
  }
  if (isOrganizationLoading) {
    return <DetailsLoading />
  }
  return (
    <Grid container sx={{ minHeight: 'calc(100vh - 122px)', display: 'flex', flexDirection: 'row', p: 3 }}>
      <Grid item xs={9}>
        <Box
          sx={{
            height: 'calc(100vh - 145px)', // Adjust the height to account for the header and fit within the viewport
            overflowY: 'auto', // Enable vertical scrolling
            pb: 2,
          }}
        >
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 25,
              mb: 1,
              bgcolor: theme.palette.background.default,
              border: '1px solid',
            }}
          >
            <RenderStrategyHeader
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              generalInfo={generalInfo}
              setGeneralInfo={setGeneralInfo}
              onSave={handleSave}
              onChange={handleSectionChange(setGeneralInfo)}
            />
          </Box>
          <RenderStrategySection
            page="Addt'l Info"
            fields={editFieldConfig["Addt'l Info"]}
            formState={addtlInfo}
            isEditMode={isEditMode}
            onChange={handleSectionChange(setAddtlInfo)}
          />
          <RenderStrategySection
            page='Integration'
            fields={editFieldConfig['Integration']}
            formState={integration}
            isEditMode={isEditMode}
            onChange={handleSectionChange(setIntegration)}
          />
          {strategyState.paidSearch && strategyState.paidSearch.length > 0 && (
            <AiStrategyContent
              sectionKey={'paidSearch'}
              sectionLabel={'Paid Search'}
              section={strategyState.paidSearch || []} // Safeguard against undefined
              isEditMode={isEditMode}
              onServiceFieldChange={handleServiceFieldChange}
              handleSectionChange={handleSectionChange}
            />
          )}

          {strategyState.paidSocial && strategyState.paidSocial.length > 0 && (
            <AiStrategyContent
              sectionKey={'paidSocial'}
              sectionLabel={'Paid Social'}
              section={strategyState.paidSocial || []} // Safeguard against undefined
              isEditMode={isEditMode}
              onServiceFieldChange={handleServiceFieldChange}
              handleSectionChange={handleSectionChange}
            />
          )}
          {strategyState.display && strategyState.display.length > 0 && (
            <AiStrategyContent
              sectionKey={'display'}
              sectionLabel={'Display'}
              section={strategyState.display || []} // Safeguard against undefined
              isEditMode={isEditMode}
              onServiceFieldChange={handleServiceFieldChange}
              handleSectionChange={handleSectionChange}
            />
          )}
          {strategyState.seo && strategyState.seo.length > 0 && (
            <AiStrategyContent
              sectionKey={'seo'}
              sectionLabel={'SEO'}
              section={strategyState.seo || []} // Safeguard against undefined
              isEditMode={isEditMode}
              onServiceFieldChange={handleServiceFieldChange}
              handleSectionChange={handleSectionChange}
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box
          sx={{
            height: 'calc(100vh - 122px)', // Adjust the height to account for the header and fit within the viewport
            overflowY: 'auto', // Enable vertical scrolling
            paddingRight: '10px', // Add padding to avoid content overflow
          }}
        >
          <StrategyNotesSection
            newNoteContent={newNoteContent}
            setNewNoteContent={setNewNoteContent}
            handleAddNote={handleAddNote}
            handleClear={handleClear}
            isCreatingNote={isCreatingNote}
            setIsCreatingNote={setIsCreatingNote}
            isEditMode={isEditMode}
            notes={notes}
            setNotes={setNotes}
          />
        </Box>
      </Grid>
      {/* Save Button */}
      <Grid item xs={12}>
        <Button variant='contained' color='primary' onClick={handleSave}>
          Save Strategy
        </Button>
      </Grid>
    </Grid>
  )
}

export default AiStrategy
