import React, { useState, useEffect } from 'react'
import { Box, Button, Typography, Grid, useTheme, TextField, InputAdornment, Tooltip, IconButton } from '@mui/material'
import {
  InfoBox,
  InfoRow,
  InfoLabel,
  InfoValue,
  InfoValueWrapper,
  Underline,
  CityStateZipRow,
  PhoneRow,
  iconValidatedStyles,
  iconInvalidatedStyles,
} from './style'
import CustomParentCompanyAutocomplete from '../CustomCompanyComponents/CustomParentCompanyAutocomplete'
import AssignOwnerAutocomplete from '../EditModeComponents/AssignOwnerAutocomplete'
import EditTypeSelect from '../EditModeComponents/EditTypeSelect'
import EditVerticalAutocomplete from '../EditModeComponents/EditVerticalAutocomplete'
import { housingTypeOptions, typeOptions } from '../CreateOrganization/CreateOrganization'
import PublicIcon from '@mui/icons-material/Public'
import SaveIconButton from '../ui/save-icon-button'
import OrgPrimaryContactAutocomplete from './OrgPrimaryContactAutocomplete'
import OrgAdditionalContactsAutocomplete from './OrgAdditionalContactsAutocomplete'
import { useValidationContext } from '../../context/ValidationProvider/ValidationProvider'
import { useOrganizationsById } from '../../api/customHooks/useOrganizationsById'
import OrgBillingContactAutocomplete from './OrgBillingContactAutocomplete'

const housingVerticals = ['Student', 'Student Housing', 'Multifamily', 'Homebuilding', 'Senior', 'Build to rent']

const EditOrganizationInfo = ({
  organization,
  billingContact,
  details,
  setDetails,
  handleChange,
  handleBlur,
  handleCompanyChange,
  handleObjectBlur,
  handleSave,
  onWebsiteBlur,
}) => {
  // console.log('details phone, email: ', details.billingPhone, details.email, details)
  const theme = useTheme()
  const { validateField, errors, setErrors } = useValidationContext()
  const hasDeals = organization?.dealIds?.length > 0
  const hasErrors = Object.values(errors).some(error => error)
  const contractedBy = useOrganizationsById(details.contractedBy ?? [])
  const affiliated = useOrganizationsById(details.affiliated ?? [])

  const handleVerticalChange = event => {
    const newVerticals = event.target.value
    const isNewVerticalHousing = newVerticals.some(vertical => housingVerticals.includes(vertical))
    const wasPreviousVerticalHousing =
      details && Array.isArray(details.vertical)
        ? details.vertical.some(vertical => housingVerticals.includes(vertical))
        : details && typeof details.vertical === 'object'
        ? details.vertical
        : []

    handleChange({ target: { name: 'vertical', value: newVerticals } })

    if (isNewVerticalHousing !== wasPreviousVerticalHousing) {
      handleChange({ target: { name: 'type', value: '' } }) // Reset type if housing status changes
    }
  }

  const handleFieldBlur = event => {
    const { name, value } = event.target
    const error = validateField(name, value)

    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: error,
    }))

    if (details[name] !== value) {
      handleBlur(event)
    }
  }
  const handleVerticalBlur = event => {
    const { name, value } = event.target

    // Log the name and value for debugging
    console.log('Vertical Blur Event:', { name, value })

    // Ensure value exists before proceeding
    if (value) {
      handleChange({ target: { name, value } })
      handleBlur({ target: { name, value } })
    } else {
      // Optionally, handle cases where there is no value
      console.warn(`No value provided for ${name}. Setting to default.`)
      handleChange({ target: { name, value: '' } })
      handleBlur({ target: { name, value: '' } })
    }
  }

  // Handle changes for type selection
  const handleTypeChange = event => {
    const { name, value } = event.target
    handleChange({ target: { name, value } })
  }

  const typeOptionsToUse =
    details && Array.isArray(details.vertical)
      ? details.vertical.some(vertical => housingVerticals.includes(vertical))
        ? housingTypeOptions
        : typeOptions
      : []

  return (
    <InfoBox sx={{ backgroundColor: theme.palette.mode === 'dark' ? '' : 'rgba(255,255,255)' }}>
      <Box sx={{ display: 'flex', justifyContent: 'right' }}>
        <SaveIconButton hasErrors={hasErrors} color='secondary' variant='contained' onSave={handleSave}>
          Save
        </SaveIconButton>
      </Box>
      <Typography variant='h6' gutterBottom>
        Information
      </Typography>
      <Grid container spacing={8}>
        <Grid item xs={12} sm={6}>
          <InfoRow>
            <InfoLabel>Owner</InfoLabel>
            <InfoValueWrapper>
              <AssignOwnerAutocomplete
                userId={(details.owner && details.owner[0]) || ''}
                label=''
                onChange={handleCompanyChange}
                context={'owner'}
                onBlur={handleObjectBlur}
                multiple={false} // Single select for owner
              />
              <Underline />
            </InfoValueWrapper>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Name</InfoLabel>
            <InfoValueWrapper>
              <Tooltip
                title={
                  <Typography sx={{ color: 'rgb(255, 211, 47)', fontSize: 13 }}>
                    {'New organization name will not be reflected on current deal(s)'}
                  </Typography>
                }
                sx={{ width: '100%' }}
              >
                <TextField
                  label=''
                  name='name'
                  value={details.name ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
              </Tooltip>
              <Underline />
            </InfoValueWrapper>
          </InfoRow>
          <InfoRow>
            <InfoLabel>DBA</InfoLabel>
            <InfoValueWrapper>
              <TextField
                label=''
                name='dba'
                value={details.dba ?? ''}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </InfoValueWrapper>
            <Underline />
          </InfoRow>
          <InfoRow>
            <Box sx={{ flex: 2 }}>
              <InfoLabel>Website</InfoLabel>
              <TextField
                label=''
                name='website'
                value={details.website ?? ''}
                onChange={handleChange}
                onBlur={onWebsiteBlur}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Tooltip title='Discovery Field'>
                        <IconButton
                          sx={{
                            padding: 0,
                            marginRight: 0.5,
                            '& .MuiSvgIcon-root': {
                              fontSize: '1rem',
                            },
                          }}
                          tabIndex={-1}
                        >
                          <PublicIcon sx={details.website ? iconValidatedStyles : iconInvalidatedStyles} />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.website}
                helperText={errors.website || ''}
                fullWidth
              />
            </Box>
          </InfoRow>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
            <Grid item xs={6}>
              <InfoLabel>Primary Contact</InfoLabel>
              <InfoValueWrapper>
                <OrgPrimaryContactAutocomplete
                  value={details.primaryContact} // Expecting an array with a single ID
                  onChange={newPrimaryContact => {
                    handleChange({ target: { name: 'primaryContact', value: newPrimaryContact } })
                  }}
                  onBlur={(name, newValue) => {
                    handleObjectBlur(name, newValue) // Handle array of one ID
                  }}
                />
                <Underline />
              </InfoValueWrapper>
            </Grid>
            <Grid item xs={6}>
              <InfoLabel>Billing Contact</InfoLabel>
              <InfoValueWrapper>
                <OrgBillingContactAutocomplete
                  value={details.billingContact} // Expecting an array of one or more IDs
                  onChange={newBillingContact => {
                    handleChange({ target: { name: 'billingContact', value: newBillingContact } })
                  }}
                  onBlur={(name, newValue) => {
                    handleObjectBlur(name, newValue) // Handle array of IDs
                  }}
                />
                <Underline />
              </InfoValueWrapper>
            </Grid>
          </Box>
          <Grid item xs={12}>
            <InfoRow>
              <InfoLabel>Additional Contacts</InfoLabel>
              <InfoValueWrapper>
                <OrgAdditionalContactsAutocomplete
                  value={details.contacts} // Expecting an array of one or more IDs
                  onChange={newAdditionalContacts => {
                    handleChange({ target: { name: 'contacts', value: newAdditionalContacts } })
                  }}
                  onBlur={(name, newValue) => {
                    handleObjectBlur(name, newValue) // Handle array of IDs
                  }}
                />
                <Underline />
              </InfoValueWrapper>
            </InfoRow>
          </Grid>
          <InfoRow>
            <InfoLabel>Contracted By</InfoLabel>
            <InfoValueWrapper>
              <CustomParentCompanyAutocomplete
                value={contractedBy ?? []}
                onChange={handleCompanyChange}
                context='contractedBy'
                type={details.type}
                onBlur={handleObjectBlur}
              />
              <Underline />
            </InfoValueWrapper>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Affiliate</InfoLabel>
            <InfoValueWrapper>
              <CustomParentCompanyAutocomplete
                value={affiliated ?? []}
                onChange={handleCompanyChange}
                context='affiliated'
                type={details.type}
                onBlur={handleObjectBlur}
              />
              <Underline />
            </InfoValueWrapper>
            <InfoLabel>Summary</InfoLabel>
            <TextField
              label=''
              name='summary'
              value={details.summary ?? ''}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
            <Underline />
          </InfoRow>
          <Box sx={{ flex: 1 }}>
            <InfoLabel>CMS Used</InfoLabel>
            <InfoValueWrapper>
              <TextField
                label=''
                name='cmsUsed'
                value={details.cmsUsed ?? ''}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Tooltip title='Discovery Field'>
                        <IconButton
                          sx={{
                            padding: 0,
                            marginRight: 0.5,
                            '& .MuiSvgIcon-root': {
                              fontSize: '1rem', // Adjust the icon size
                            },
                          }}
                          tabIndex={-1} // Ensure the icon is not focusable
                        >
                          <PublicIcon sx={details.cmsUsed ? iconValidatedStyles : iconInvalidatedStyles} />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </InfoValueWrapper>

            <InfoLabel>Active Social Profiles</InfoLabel>
            {details && details.social && Array.isArray(details.social) && details.social.length > 0 ? (
              <InfoValueWrapper>
                <TextField
                  label=''
                  name='socialProfiles'
                  value={'Social profiles were determined by website provided'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  disabled // Disable the TextField if profiles exist
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Tooltip title='Discovery Field'>
                          <IconButton
                            sx={{
                              padding: 0,
                              marginRight: 0.5,
                              '& .MuiSvgIcon-root': {
                                fontSize: '1rem', // Adjust the icon size
                              },
                            }}
                            tabIndex={-1} // Ensure the icon is not focusable
                          >
                            <PublicIcon
                              sx={
                                details && details.social && details.social.length > 0
                                  ? iconValidatedStyles
                                  : iconInvalidatedStyles
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </InfoValueWrapper>
            ) : (
              <InfoValueWrapper>
                <TextField
                  label=''
                  name='socialProfiles'
                  value={details.socialProfiles ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Tooltip title='Discovery Field'>
                          <IconButton
                            sx={{
                              padding: 0,
                              marginRight: 0.5,
                              '& .MuiSvgIcon-root': {
                                fontSize: '1rem', // Adjust the icon size
                              },
                            }}
                            tabIndex={-1} // Ensure the icon is not focusable
                          >
                            <PublicIcon sx={details.socialProfiles ? iconValidatedStyles : iconInvalidatedStyles} />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </InfoValueWrapper>
            )}
          </Box>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <InfoLabel>Facebook Access</InfoLabel>
          {details && (!details.website || details.website === 'No Website Assigned') && (
            <InfoValueWrapper>
              <TextField
                label=''
                name='facebookAccess'
                value={details.facebookAccess ?? ''}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </InfoValueWrapper>
          )}
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <InfoRow>
            <InfoLabel>Vertical</InfoLabel>
            <InfoValueWrapper>
              <EditVerticalAutocomplete
                name='vertical'
                value={details && details.vertical && Array.isArray(details.vertical) ? details.vertical : []}
                onChange={handleVerticalChange}
                onBlur={handleVerticalBlur}
                hasDeals={hasDeals}
              />
              <Underline />
            </InfoValueWrapper>
          </InfoRow>
          <InfoRow>
            <InfoLabel>Type</InfoLabel>
            <InfoValueWrapper>
              <EditTypeSelect
                name='type'
                value={details.type}
                options={typeOptionsToUse}
                onChange={handleTypeChange}
                onBlur={handleFieldBlur}
                hasDeals={hasDeals}
              />
              <Underline />
            </InfoValueWrapper>
          </InfoRow>

          <PhoneRow>
            <Box sx={{ flex: 1, mx: 1 }}>
              <InfoLabel>Business Phone</InfoLabel>
              <TextField
                label=''
                name='businessPhone'
                value={details.businessPhone ?? ''}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
            </Box>
            <Box sx={{ flex: 1, mx: 1 }}>
              <InfoValueWrapper>
                <InfoLabel>Billing Phone</InfoLabel>
                <TextField
                  label=''
                  name='billingPhone'
                  value={
                    billingContact && billingContact.phone
                      ? billingContact.phone
                      : details && details.billingPhone
                      ? details.billingPhone
                      : '...'
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled
                  fullWidth
                />
              </InfoValueWrapper>
            </Box>
            <Underline />
          </PhoneRow>
          <InfoRow>
            <InfoLabel>Billing Email</InfoLabel>
            <TextField
              label=''
              name='email'
              value={
                billingContact && billingContact.email
                  ? billingContact.email
                  : details && details.email
                  ? details.email
                  : '...'
              }
              onChange={handleChange}
              onBlur={handleBlur}
              disabled
              fullWidth
            />
          </InfoRow>
          <InfoRow>
            <InfoLabel>Street</InfoLabel>
            <TextField
              label=''
              name='street'
              value={details.street ?? ''}
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Tooltip title='Discovery Field'>
                      <IconButton
                        sx={{
                          padding: 0,
                          marginRight: 0.5,
                          '& .MuiSvgIcon-root': {
                            fontSize: '1rem', // Adjust the icon size
                          },
                        }}
                        tabIndex={-1}
                      >
                        <PublicIcon sx={details.street ? iconValidatedStyles : iconInvalidatedStyles} />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </InfoRow>
          <CityStateZipRow>
            <Box sx={{ flex: 2 }}>
              <InfoLabel>City</InfoLabel>
              <TextField
                label=''
                name='city'
                value={details.city ?? ''}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Tooltip title='Discovery Field'>
                        <IconButton
                          sx={{
                            padding: 0,
                            marginRight: 0.5,
                            '& .MuiSvgIcon-root': {
                              fontSize: '1rem', // Adjust the icon size
                            },
                          }}
                          tabIndex={-1} // Ensure the icon is not focusable
                        >
                          <PublicIcon sx={details.city ? iconValidatedStyles : iconInvalidatedStyles} />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </Box>
            <Box sx={{ flex: 1, mx: 2 }}>
              <InfoLabel>State</InfoLabel>
              <InfoValueWrapper>
                <TextField
                  label=''
                  name='state'
                  value={details.state ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Tooltip title='Discovery Field'>
                          <IconButton
                            sx={{
                              padding: 0,
                              marginRight: 0.5,
                              '& .MuiSvgIcon-root': {
                                fontSize: '1rem', // Adjust the icon size
                              },
                            }}
                            tabIndex={-1}
                          >
                            <PublicIcon sx={details.state ? iconValidatedStyles : iconInvalidatedStyles} />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </InfoValueWrapper>
            </Box>
            <Box sx={{ flex: 1 }}>
              <InfoLabel>Zip</InfoLabel>
              <InfoValueWrapper>
                <TextField
                  label=''
                  name='zip'
                  value={details.zip ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Tooltip title='Discovery Field'>
                          <IconButton
                            sx={{
                              padding: 0,
                              marginRight: 0.5,
                              '& .MuiSvgIcon-root': {
                                fontSize: '1rem', // Adjust the icon size
                              },
                            }}
                            tabIndex={-1}
                          >
                            <PublicIcon sx={details.zip ? iconValidatedStyles : iconInvalidatedStyles} />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </InfoValueWrapper>

              {/* <Box sx={{ width: '100%' }}>
                <TextField
                  label='TapClicks Client ID'
                  name='tapclicksClientId'
                  // value={newOrganization.tapclicksClientId}
                  // onChange={handleChange}
                  fullWidth
                />
              </Box> */}
            </Box>
            <Underline />
          </CityStateZipRow>

          <Box sx={{ flex: 1 }}>
            <InfoLabel>Marketing Objective</InfoLabel>
            <InfoValueWrapper>
              <TextField
                label=''
                name='marketingObjective'
                value={details.marketingObjective ?? ''}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Tooltip title='Discovery Field'>
                        <IconButton
                          sx={{
                            padding: 0,
                            marginRight: 0.5,
                            '& .MuiSvgIcon-root': {
                              fontSize: '1rem', // Adjust the icon size
                            },
                          }}
                          tabIndex={-1} // Ensure the icon is not focusable
                        >
                          <PublicIcon sx={details.marketingObjective ? iconValidatedStyles : iconInvalidatedStyles} />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </InfoValueWrapper>
            <InfoLabel>Geographic Targeting</InfoLabel>
            <InfoValueWrapper>
              <TextField
                label=''
                name='geoTargeting'
                value={details.geoTargeting ?? ''}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Tooltip title='Discovery Field'>
                        <IconButton
                          sx={{
                            padding: 0,
                            marginRight: 0.5,
                            '& .MuiSvgIcon-root': {
                              fontSize: '1rem', // Adjust the icon size
                            },
                          }}
                          tabIndex={-1} // Ensure the icon is not focusable
                        >
                          <PublicIcon sx={details.geoTargeting ? iconValidatedStyles : iconInvalidatedStyles} />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </InfoValueWrapper>
            <InfoLabel>Product & services to focus on</InfoLabel>
            <InfoValueWrapper>
              <TextField
                label=''
                name='productsAndServices'
                value={details.productsAndServices ?? ''}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Tooltip title='Discovery Field'>
                        <IconButton
                          sx={{
                            padding: 0,
                            marginRight: 0.5,
                            '& .MuiSvgIcon-root': {
                              fontSize: '1rem', // Adjust the icon size
                            },
                          }}
                          tabIndex={-1} // Ensure the icon is not focusable
                        >
                          <PublicIcon sx={details.productsAndServices ? iconValidatedStyles : iconInvalidatedStyles} />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </InfoValueWrapper>
            <InfoLabel>Facebook Access</InfoLabel>
            {details && (!details.website || details.website === 'No Website Assigned') && (
              <InfoValueWrapper>
                <TextField
                  label=''
                  name='facebookAccess'
                  value={details.facebookAccess ?? ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
              </InfoValueWrapper>
            )}
          </Box>
        </Grid>
      </Grid>
    </InfoBox>
  )
}

export default EditOrganizationInfo
