import isEqual from 'lodash.isequal'

export const determineApprovalState = (selectedDeal, organization) => {
  const csaTypes = ['CSA', 'RCSA']
  const msaTypes = ['MSA', 'RMSA', 'MSA - Housing', 'RMSA - Housing']
  const typesToSkipApproval = ['IO']
  const verticalsToSkipApproval = ['Student', 'Multifamily', 'Student Housing']

  let approvalState = {
    hasProducts: selectedDeal && Array.isArray(selectedDeal.products) && selectedDeal.products.length > 0,
    needsApproval: false,
    status: selectedDeal?.status || 'Draft', // Preserve existing status if present
    stage: selectedDeal?.stage || 'Discovery', // Preserve existing stage if present
  }

  if (csaTypes.includes(selectedDeal?.type)) {
    approvalState.needsApproval = true
    approvalState.stage = approvalState.hasProducts ? 'Pending Approval' : 'Discovery'
    approvalState.status = approvalState.hasProducts ? 'Needs Approval' : 'Draft'
  } else if (msaTypes.includes(selectedDeal?.type)) {
    const needsApproval = !verticalsToSkipApproval.includes(
      organization && organization.vertical && Array.isArray(organization.vertical) ? organization.vertical[0] : ''
    )
    approvalState.needsApproval = needsApproval
    if (needsApproval) {
      approvalState.stage = approvalState.hasProducts ? 'Pending Approval' : 'Discovery'
      approvalState.status = approvalState.hasProducts ? 'Needs Approval' : 'Draft'
    } else {
      approvalState.stage = approvalState.hasProducts ? 'Proposal' : 'Discovery'
      approvalState.status = approvalState.hasProducts ? 'Saved' : 'Draft'
    }
  } else if (typesToSkipApproval.includes(selectedDeal?.type)) {
    approvalState.needsApproval = false
    approvalState.stage = approvalState.hasProducts ? 'Proposal' : 'Discovery'
    approvalState.status = approvalState.hasProducts ? 'Saved' : 'Draft'
  }

  if (selectedDeal?.noSignature) {
    approvalState.needsApproval = false
    approvalState.stage = 'Deal Won'
    approvalState.status = 'Completed Internally'
  }

  return approvalState
}
export const handleEditDealState = (
  hasProducts,
  existingDeal,
  existingType,
  newSelectedType,
  organization,
  originalApprovalState
) => {
  // console.log('Existing Deal: ', existingDeal, 'existingType:', existingType, 'originalState', originalApprovalState)
  const csaTypes = ['CSA', 'RCSA']
  const msaTypes = ['MSA', 'RMSA', 'MSA - Housing', 'RMSA - Housing']
  const ioTypes = ['IO']
  const verticalsToSkipApproval = ['Student', 'Multifamily', 'Student Housing']
  // // Initialize the original state and the updated state
  // const originalState = {
  //   hasProducts: existingDeal?.products?.length > 0,
  //   needsApproval: existingDeal?.needsApproval ?? false,
  //   status: existingDeal?.status ?? 'Draft',
  //   stage: existingDeal?.stage ?? 'Discovery',
  // }

  let updatedDealState = { ...originalApprovalState }

  // If the deal type has changed, handle approval logic
  // console.log('hasProducts? ', hasProducts)

  // Apply your logic to update the deal state only when the type has changed or specific logic is needed
  if (csaTypes.includes(newSelectedType)) {
    updatedDealState.needsApproval = true
    updatedDealState.status = hasProducts ? 'Needs Approval' : 'Draft'
    updatedDealState.stage = hasProducts ? 'Pending Approval' : 'Discovery'
  } else if (msaTypes.includes(newSelectedType)) {
    const needsApproval = !verticalsToSkipApproval.includes(organization?.vertical?.[0] || '')
    if (needsApproval) {
      updatedDealState.needsApproval = needsApproval
      updatedDealState.status = hasProducts ? 'Needs Approval' : 'Draft'
      updatedDealState.stage = hasProducts ? 'Pending Approval' : 'Discovery'
    } else if (!needsApproval) {
      updatedDealState.status = hasProducts ? 'Saved' : 'Draft'
      updatedDealState.stage = hasProducts ? 'Proposal' : 'Discovery'
    }
  } else if (ioTypes.includes(newSelectedType)) {
    updatedDealState.needsApproval = false
    updatedDealState.status = hasProducts ? 'Saved' : 'Draft'
    updatedDealState.stage = hasProducts ? 'Proposal' : 'Discovery'
  }
  // Handle deal without signature
  if (existingDeal?.noSignature) {
    updatedDealState.needsApproval = false
    updatedDealState.status = 'Completed Internally'
    updatedDealState.stage = 'Deal Won'
  }
  if (hasProducts) {
    updatedDealState.hasProducts = true
  } else {
    updatedDealState.hasProducts = false
  }
  // console.log('Updated State: ', updatedDealState)

  // Use lodash's isEqual to deeply compare originalState and updatedDealState
  if (
    updatedDealState.hasProducts === originalApprovalState.hasProducts &&
    updatedDealState.needsApproval === originalApprovalState.needsApproval &&
    updatedDealState.stage === originalApprovalState.stage &&
    updatedDealState.status === originalApprovalState.status &&
    updatedDealState.isApproved === originalApprovalState.isApproved &&
    updatedDealState.isCompleted === originalApprovalState.isCompleted
  ) {
    console.log('EQUAL STATES, returning original: ', originalApprovalState)
    return originalApprovalState
  } else {
    console.log('DIFFERENT STATES, returning updated: ', updatedDealState)
    return updatedDealState
  }
}

// export const handleEditDealState = (existingDeal, existingType, organization, originalApprovalState) => {
//   console.log('Existing Deal: ', existingDeal)
//   const csaTypes = ['CSA', 'RCSA']
//   const msaTypes = ['MSA', 'RMSA', 'MSA - Housing', 'RMSA - Housing']
//   const typesToSkipApproval = ['IO']
//   const verticalsToSkipApproval = ['Student', 'Multifamily', 'Student Housing']

//   // Initialize the original state and the updated state
//   const originalState = {
//     hasProducts: existingDeal?.products?.length > 0,
//     needsApproval: existingDeal?.needsApproval ?? false,
//     status: existingDeal?.status ?? 'Draft',
//     stage: existingDeal?.stage ?? 'Discovery',
//   }

//   let updatedDealState = { ...originalState }

//   // If the deal type has changed, handle approval logic
//   const hasTypeChanged = existingType && existingType !== existingDeal.type

//   // Apply your logic to update the deal state only when the type has changed or specific logic is needed
//   if (csaTypes.includes(existingDeal?.type)) {
//     updatedDealState.needsApproval = true
//     if (updatedDealState.hasProducts) {
//       updatedDealState.status = 'Needs Approval'
//       updatedDealState.stage = 'Pending Approval'
//     }
//   } else if (msaTypes.includes(existingDeal?.type)) {
//     const needsApproval = !verticalsToSkipApproval.includes(organization?.vertical?.[0] || '')
//     updatedDealState.needsApproval = needsApproval

//     if (needsApproval && updatedDealState.hasProducts) {
//       updatedDealState.status = 'Needs Approval'
//       updatedDealState.stage = 'Pending Approval'
//     } else if (!needsApproval && updatedDealState.hasProducts) {
//       updatedDealState.status = 'Saved'
//       updatedDealState.stage = 'Proposal'
//     }
//   } else if (typesToSkipApproval.includes(existingDeal?.type)) {
//     updatedDealState.needsApproval = false
//     if (updatedDealState.hasProducts) {
//       updatedDealState.status = 'Saved'
//       updatedDealState.stage = 'Proposal'
//     }
//   }

//   if (existingDeal?.noSignature) {
//     updatedDealState.needsApproval = false
//     updatedDealState.status = 'Completed Internally'
//     updatedDealState.stage = 'Deal Won'
//   }

//   // Check if the type has changed
//   if (hasTypeChanged) {
//     // If the type changes from MSA to MSA-Housing or other similar cases, update the status and stage
//     if (msaTypes.includes(existingDeal?.type)) {
//       if (msaTypes.includes(existingDeal?.originalType)) {
//         // Handle changes within MSA types (e.g., MSA -> MSA-Housing)
//         updatedDealState.status = 'Updated Due to Type Change'
//         updatedDealState.stage = 'Type Updated'
//       }
//     }
//   }

//   // Use lodash's isEqual to deeply compare originalState and updatedDealState
//   if (isEqual(updatedDealState, originalState)) {
//     console.log('EQUAL STATES')
//     return originalApprovalState
//   } else {
//     return updatedDealState
//   }
// }
