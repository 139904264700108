import React, { useState, useEffect, useContext } from 'react'
import { Box, useTheme } from '@mui/material'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { gridContainerStyles } from './style'
import CustomGridToolbar from '../DataGrid/CustomGridToolbar'
import { useSnackbarContext } from '../../context/SnackbarProvider/SnackbarProvider'
import { useDeleteMutation } from '../../api/aws/useDeleteMutation'
import { useSprints } from '../../api/aws/useSprints'
import { renderSprintsColumns } from './renderSprintsColumns'
import Sprints from './Sprints.tsx'
import { SprintsGridFooter } from './SprintsGridFooter.js'
import './sprint.css'

const SprintsPage = () => {
  const { sprints, isSprintsLoading, isSprintsError } = useSprints()

  const { showSnackbar } = useSnackbarContext()
  const theme = useTheme()
  const [selectedSprint, setSelectedSprint] = useState(null)
  const [selectedSprints, setSelectedSprints] = useState(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const [selectionModel, setSelectionModel] = useState([])
  const [filterModel, setFilterModel] = useState({ items: [] })
  const { mutate: deleteItem, isLoading } = useDeleteMutation()
  const apiRef = useGridApiRef()
  const [visibleRowCount, setVisibleRowCount] = useState(sprints ? sprints.length : 0)
  const [rowCount, setRowCount] = useState(sprints ? sprints.length : 0)
  const [anchorEl, setAnchorEl] = useState(null)
  useEffect(() => {
    if (sprints && selectionModel.length === 1) {
      const newLead = sprints.find(lead => lead.lead_uuid === selectionModel[0])
      setSelectedSprints(newLead)
      // setMassUpdateData({ vertical: [], type: '' })
    } else {
      setSelectedSprint(null)
    }
  }, [selectionModel, sprints])

  const handleDeleteClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleConfirmDelete = async () => {
    setIsDeleting(true)
    if (selectedSprint) {
      const params = {
        endpoint: `/aws/delete/sprints/${selectedSprint.id}`,
        table: 'sprints',
      }
      deleteItem(params, {
        onSuccess: message => {
          setSelectedSprint(null)
          showSnackbar(message, 'success')
          setIsDeleting(false)
        },
        onError: error => {
          showSnackbar(error.message, 'error')
          setIsDeleting(false)
        },
      })
    }
  }
  const handleColumnResizeStop = () => {
    if (apiRef.current) {
      apiRef.current.updateColumns()
    }
  }

  const columns = renderSprintsColumns()
  // Define a function to return styles based on row type using inline `sx`
  const handleRowSelected = sprint => {
    setSelectedSprint(sprint)
  }
  const handleSelectionModelChange = newSelectionModel => {
    console.log('newSelectionModel: ', newSelectionModel)
    if (newSelectionModel.length !== selectionModel.length) {
      setSelectionModel(newSelectionModel)
      if (newSelectionModel.length === 0 && handleRowSelected) {
        handleRowSelected(null)
      } else {
        const selectedRowData = sprints.find(row => row.sprint_uuid === newSelectionModel[0])
        if (handleRowSelected) {
          handleRowSelected(selectedRowData)
        }
      }
    }
  }
  const handleClosePopover = () => {
    setAnchorEl(null)
    if (selectionModel.length > 0) {
      selectionModel.forEach(id => {
        apiRef.current.selectRow(id, false)
      })
      setSelectionModel([])
      setSelectedSprint(null)
    }
  }
  useEffect(() => {
    const handleFilterModelChange = () => {
      const filteredRowsLookup = apiRef.current.state.filter.filteredRowsLookup
      const filteredRowsCount = Object.values(filteredRowsLookup).filter(isVisible => isVisible).length
      setVisibleRowCount(filteredRowsCount)
    }

    const api = apiRef.current
    const unsubscribe = api.subscribeEvent('filterModelChange', handleFilterModelChange)

    // Initialize the visible row count when the component mounts
    handleFilterModelChange()

    return () => {
      unsubscribe()
    }
  }, [apiRef])
  const totalRowCount = sprints ? sprints.length : 0

  return (
    <>
      <Box>
        <Sprints />
      </Box>
      <Box sx={{ ...gridContainerStyles, height: 'calc(100vh - 500px)' }}>
        <DataGridPro
          initialState={{
            sorting: {
              sortModel: [{ field: 'creation_timestamp', sort: 'desc' }],
            },
            columns: {
              columnVisibilityModel: {},
            },
          }}
          rows={sprints || []}
          columns={columns}
          rowHeight={52}
          getRowClassName={params =>
            params && params.row && !params.row.isCompleted
              ? `sprint-grid-row--${params.row.type}`
              : `sprint--completed`
          }
          getRowId={row => row.sprint_uuid || row.id}
          checkboxSelection
          disableRowSelectionOnClick
          selectionModel={selectionModel}
          onRowSelectionModelChange={newSelectionModel => handleSelectionModelChange(newSelectionModel)}
          // onRowSelectionModelChange={newSelectionModel => handleSelectionModelChange(newSelectionModel)}
          onRowCountChange={count => setRowCount(count)}
          filterModel={filterModel}
          onFilterModelChange={model => setFilterModel(model)}
          apiRef={apiRef}
          slots={{
            // toolbar: CustomGridToolbar,
            footer: SprintsGridFooter,
          }}
          slotProps={{
            // toolbar: {
            //   filterModel: filterModel,
            //   setFilterModel: setFilterModel,
            //   context: 'sprints',
            // },
            footer: {
              selectionModel: selectionModel,
              totalRowCount: totalRowCount,
              visibleRowCount: visibleRowCount,
              rowCount: rowCount,
              sprints: sprints,
              anchorEl: anchorEl,
              handleDeleteClick: handleDeleteClick,
              handleConfirmDelete: handleConfirmDelete,
              handleClosePopover: handleClosePopover,
              selectedData: selectedSprint,
            },
          }}
          onColumnResizeStop={handleColumnResizeStop}
          disableExtendRowFullWidth
        />
      </Box>
    </>
  )
}

export default SprintsPage
